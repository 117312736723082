<script>
//import vue2Dropzone from "vue2-dropzone";
//import Multiselect from "vue-multiselect";
import {
  required,
  email,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ClientService from "@/services/ClientService";
import AccountService from "@/services/AccountService";
import moment from "moment";

const clientService = new ClientService();
const accountService = new AccountService();
import {  mapState } from "vuex";

//import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import "vue-multiselect/dist/vue-multiselect.min.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Agregar prestamista",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    //vueDropzone: vue2Dropzone,
    //Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Prestamista",
      infoPage:{
        currencys:['MXN', 'USD'],
        view: 'add',
        routeBack: '/prestamistas/list'
      },

      /*objectData:{
        name: 'develop',
        lastname: 'beta',
        street: 'null',
        number_ext: '1000',
        number_int: null,
        zipcode: '00000',
        colony: 'dev',
        city: 'dev', 
        state: 'dev',
        phone: '',
        email: 'develop@digitalma.mx',
        celphone: '0000000000',
        comment: null,
        id: null
      },*/
      objectData:{
        name: null,
        lastname: null,
        street: null,
        number_ext: null,
        number_int: null,
        zipcode: null,
        colony: null,
        city: null, 
        state: null,
        phone: null,
        email: null,
        celphone: null,
        comment: null,
        id: null
      },
      idsDelete:[],
      accounts:[],



  

      items: [
        {
          text: "Inicio",
        },
        {
          text: "Agregar prestamista",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      submitted: false,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
  },
  validations: {
      objectData: {
        name: {
          required,
        },
        lastname: {
          required,
        },
        number_ext: {
          required,
        },
        street: {
          required,
        },
        colony: {
          required,
        },
        city: {
          required,
        },
        state: {
          required,
        },
        zipcode: {
          required,
        },
        celphone: {
          required,
        },
        email:{
          required,
          email
        }
      },
    },
  middleware: "authentication",
  computed:{

  ...mapState('auth', ['currentUser']),
  },
  created(){

    this.chargeItems();
  },
 methods: {

    chargeItems(){

      if(this.$route.name==='prestamistasEdit' || this.$route.name==='prestamistasShow' ){
        this.infoPage.view = 'edit';
        if(this.$route.name==='prestamistasShow'){
          this.infoPage.view = 'show';
        }
        this.items[1].text = 'Editar prestamista';
        clientService.getById(this.$route.params.id).then(async response=>{
          let data = response.data.client;
         for(let prop in data){
           this.objectData[prop] = data[prop];
         }
          //console.log(response.data.client);
          let params = {
            client_id: this.$route.params.id
          }

          let responseItems = await accountService.getList(params);
          
          this.accounts = responseItems.data.accounts;


        });
      }
      if(this.accounts.length===0){
        this.accounts.push({
          name: null,
          currency: 'MXN',
          interest: null,
          cut_date: null,
          amount: null,
          latest_retirement: null,
          id: null
        });
      }
      

    },
    // eslint-disable-next-line no-unused-vars
    addAccount() {
      if(this.accounts.length<2){
        this.accounts.push({
          name: null,
          start_date: null,
          currency: 'MXN',
          interest: null,
          cut_date: null,
          amount: null,
          latest_retirement: null,
          id: null
        });
      }
      
    },
    async formSubmit() {
      //console.log('enviado', this.$v);
      this.submitted = true;
      if(this.$v.$error===false &&  this.$v.$invalid===false){
        console.log('validado');
        let params = {...this.objectData}
        
        if( this.infoPage.view === 'edit'){
          await clientService.update(params).then(async requestResponse=>{
            
            if(requestResponse.data.code===200){
              requestResponse = requestResponse.data.client;
                let idx = 0;
                while(idx<this.accounts.length){
                  let element =this.accounts[idx];
                  //this.accounts.forEach(async element => {
                  element.start_date = moment().format('YYYY-MM-DD');
                  element.client_id = requestResponse.id;
                  if(element.id===null){
                    element.amount = 0;
                    await accountService.create(element)
                  }else{
                    await accountService.update(element)
                  }
                  idx++;
                }
                let idsDelete =  this.idsDelete;
                if(idsDelete.length){
                  await this.deleteAccounts();
                }
           
            }else{
              this.errorMsg(requestResponse.data.message);
            }
          });
          
        }else{
          delete params.id;
          
          await clientService.create(params).then(async requestResponse=>{
            if(requestResponse.data.code===200){ 
              requestResponse = requestResponse.data.client;
              //console.log('requestResponse', );
              let idx = 0;

              while(idx<this.accounts.length){
                let element =this.accounts[idx];
                delete element.id;
                element.amount = 0;
                element.start_date = moment().format('YYYY-MM-DD');
                element.client_id = requestResponse.id;
                await accountService.create(element)
                idx++;
              }
              
              this.successForm(this.infoPage.routeBack);
            }else{
              this.errorMsg(requestResponse.data.message);
            }
          });
        }
      }
      
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    errorMsg(msg){
      Swal.fire("¡Operación cancelada!", msg, "warning")

      Swal.fire({
        title: "¡Operación cancelada!",
        icon: "warning",
        text:msg,
        focusConfirm: false,
        confirmButtonText: 'Ok',
      });
    },
    successForm(route){
      Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
       this.$router.push({
            path: route,
          });
      });
    },
    cancelForm(){

      if(this.currentUser.role === 3 ){
        this.$router.push({
          path: '/intereses/list',
        });
      }else{
        this.$router.push({
          path: this.infoPage.routeBack,
        });
      }
     
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    deleteRow(idx){
      let id = this.accounts[idx].id;
      if(id!==null){
        accountService.checkInfo(id).then(async (response)=>{

          response = response.data;
          if(response.msg==='ok'){
            console.log('parta borar', id);
            this.idsDelete.push(id);
            this.accounts.splice(idx, 1);
          }
          if(response.msg==='error'){

            this.errorForm(response.errorsResponse);
          }
      });
    }else{
      this.accounts.slice(idx, 1);
    }
  },
      errorForm(errorResponse) {
      let htmlResponse = '';

      errorResponse.forEach(element => {
        htmlResponse = htmlResponse +' '+element+ ' </br>'
        
      });
      Swal.fire({
        title: "¡Operación cancelada!",
        icon: "warning",
        html:htmlResponse,
        focusConfirm: false,
        confirmButtonText: 'Ok',
      });
    },
 async deleteAccounts(){
    let idx =0;
    let idsDelete =  this.idsDelete;

    while(idx<idsDelete.length){
      let id = idsDelete[idx];
      await accountService.delete(id);
      idx++;
    }
    return;
  }

 }


};
</script>

<template>
  <Layout>
    <form  class="needs-validation" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Datos generales</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completar información del prestamista
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Nombre(s)</label>
                        <input
                          id="name"
                          v-model="objectData.name"
                          type="text"
                          class="form-control"
                          placeholder="Nombre"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.name.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.name.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="validationCustom02">Apellidos</label>
                        <input
                          id="validationCustom02"
                          v-model="objectData.lastname"
                          type="text"
                          class="form-control"
                          placeholder="Apellidos"
                          value="Otto"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.lastname.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.lastname.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.lastname.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>


               
                  <div class="row">
              
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="validationCustom03">Calle</label>
                        <input
                          id="validationCustom03"
                          v-model="objectData.street"
                          type="text"
                          class="form-control"
                          placeholder="Calle"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.street.$error,
                          }"
                        />
                        <div v-if="submitted && $v.objectData.street.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.street.required">Campo requerido</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="validationCustom03">Número Ext</label>
                        <input
                          id="validationCustom03"
                          v-model="objectData.number_ext"
                          type="text"
                          class="form-control"
                          placeholder="# Exterior"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.number_ext.$error,
                          }"
                        />
                        <div v-if="submitted && $v.objectData.number_ext.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.city.required">Campo requerido</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="validationCustom03">Número Int</label>
                        <input
                          id="validationCustom03"
                          v-model="objectData.number_int"
                          type="text"
                          class="form-control"
                          :disabled="infoPage.view==='show'"
                          placeholder="# Interior"
                          
                        />
                       
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="validationCustom03">Colonia</label>
                        <input
                          id="validationCustom03"
                          v-model="objectData.colony"
                          type="text"
                          class="form-control"
                          placeholder="Colonia"
                          :disabled="infoPage.view==='show'"
                          :class="{'is-invalid': submitted && $v.objectData.colony.$error,}"/>
                        <div v-if="submitted && $v.objectData.colony.$error" class="invalid-feedback">
                          <span v-if="!$v.objectData.colony.required">Campo requerido</span>
                        </div>
                      </div>
                    </div>
                  
                  </div>


                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label for="validationCustom03">Ciudad</label>
                        <input
                          id="validationCustom03"
                          v-model="objectData.city"
                          type="text"
                          class="form-control"
                          placeholder="Ciudad"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.city.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.city.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.objectData.city.required"
                            >Campo requerido.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label for="validationCustom04">Estado</label>
                        <input
                          id="validationCustom04"
                          v-model="objectData.state"
                          type="text"
                          class="form-control"
                          placeholder="Estado"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.state.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.state.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.objectData.state.required"
                            >Campo requerido.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label for="validationCustom75">Código Postal</label>
                        <input
                          id="validationCustom75"
                          v-model="objectData.zipcode"
                          type="text"
                          class="form-control"
                          placeholder="Código postal"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.zipcode.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.zipcode.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.objectData.zipcode.required"
                            >Campo requerido.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  <div class="row">
                    <div class="col-md-4">
                  
                  
                      <div class="mb-3">
                        <label>Correo electrónico</label>
                          <input
                            v-model="objectData.email"
                            type="email"
                            name="email"
                            class="form-control"
                          :disabled="infoPage.view==='show'"
                            :class="{
                              'is-invalid': submitted && $v.objectData.email.$error,
                            }"
                            placeholder="Ingresar Email"
                          />
                          <div v-if="submitted && $v.objectData.email.$error" class="invalid-feedback">
                            <span v-if="!$v.objectData.email.required"
                              >Campo requerido.</span>
                            <span v-if="!$v.objectData.email.email"
                              >Añadir un correo eléctronico válido.</span>
                          </div>
                      </div>
                    </div>
                    
                    <div class="col-md-4">
                      <div class="form-group mb-3">
                          <label>Teléfono celular</label>
                          <input
                            v-model="objectData.celphone"
                            v-mask="'(###) ###-####'"
                            type="text"
                            class="form-control"
                          :disabled="infoPage.view==='show'"
                          :class="{
                            'is-invalid': submitted && $v.objectData.celphone.$error,
                          }"
                          />
                          <span class="text-muted">Ejemp. "(xxx) xxx-xxxx"</span>
                           <div
                          v-if="submitted && $v.objectData.celphone.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.objectData.celphone.required"
                            >Campo requerido.</span
                          >
                        </div>
                      </div>
                       
                    </div>
                  
                    <div class="col-md-4">
                      <div class="form-group mb-3">
                          <label>Teléfono casa</label>
                          <input
                            v-model="objectData.phone"
                            v-mask="'(###) ###-####'"
                          :disabled="infoPage.view==='show'"
                            type="text"
                            class="form-control"
                          />
                          <span class="text-muted">Ejemp. "(xxx) xxx-xxxx"</span>
                      </div>
                    </div>
                
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3 mb-0">
                        <label for="productdesc">Comentarios</label>
                        <textarea
                        v-model="objectData.comment"
                          class="form-control"
                          id="productdesc"
                          :disabled="infoPage.view==='show'"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>


                  
                  
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="addproduct-img-collapse"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Cuentas activas</h5>
                    <p class="text-muted text-truncate mb-0">
                      Max 2 cuentas activas
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
               <div class="p-4 border-top">
                <div
                  v-for="(account, index) in accounts"
                  :key="account.id"
                  class="row">
                  <div class="mb-3 col-lg-2">
                    <label for="name">Nombre</label>
                    <input
                      id="name"
                      v-model="account.name"
                      type="text"
                      name="untyped-input"
                      class="form-control"
                          :disabled="infoPage.view==='show'"
                    />
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="email">Interés</label>
                    <input
                      id="email"
                      v-model="account.interest"
                      type="email"
                      class="form-control"
                          :disabled="infoPage.view==='show'"
                    />
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="subject">Moneda</label>
                    <select :disabled="account.id!==null || infoPage.view==='show'" class="form-control select2" v-model="account.currency">
                        <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
                        </select>
                  </div>

                  <div class="mb-3 col-lg-2">
                    <label for="resume">Dia de  corte</label>
                      <input
                      id="subject"
                      v-model="account.cut_date"
                      type="text"
                      class="form-control"
                          :disabled="infoPage.view==='show'"
                    />
                  </div>

                  

                  <div class="col-lg-2  d-grid mb-3">
                    <label for="resume"   v-if="infoPage.view!=='show'"> Acción</label>
                    <input
                      type="button"
                      class="btn btn-primary btn-block"
                      value="Eliminar"
                      
                          v-if="infoPage.view!=='show'"
                      @click="deleteRow(index)"
                    />
                  </div>
                </div>
              
            <input
                type="button"
                class="btn btn-success mt-3 mt-lg-0"
                value="Agregar cuenta"
                v-show="accounts.length<2"
                :v-if="infoPage.view!=='show'"
                @click="addAccount()"
              />
            </div>
            </b-collapse>
          </div>

          
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger" @click.prevent="cancelForm()">
          <i class="uil  me-1" :class="infoPage.view!=='show'?'uil-times':'uil-arrow-circle-left'"></i> {{infoPage.view!=='show'?'Cancelar':'Regresar'}}
        </a>
        <button   class="btn btn-success ms-1" @click.prevent="formSubmit" v-if="infoPage.view!=='show'">
          <i class="uil uil-file-alt me-1"></i> Guardar
        </button>
      </div>
      <!-- end col -->
    </div>
    </form>
  </Layout>
</template>
